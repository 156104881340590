<template>
  <div class="Site">
    <NavBar :title="$t('memberRrading.default[0]')" :page-number="-1"/>
    <div class="ScrollBox">

      <div class="order-item" style="height: 74px;">
        <div v-if="userInfo.header != null" style="display: flex; width: 100%">
          <div style="padding-left :20px; height: 128px; width: 70%">
            <div style="display: flex; margin-top: 20px;">
              <div>
                <img
                     :src="userInfo.header.includes('head_')?`./static/head/${userInfo.header}`:userInfo.header"
                     width="36" height="36"/></div>
              <div style="margin-left: 8px;">
                {{ userInfo.username }}
              </div>
              <div v-if="userInfo.user_type==2" style="margin-left: 4px;"><img
                  src="~@/assets/img/skinbitcoin/wangg.png" style="width: 20px;"/></div>
            </div>
          </div>
        </div>
      </div>

      <div class="records" @touchstart="startSwipe" @touchend="endSwipe">
        <van-list
            v-model="isLoad"
            :finished="isFinished"
            :finished-text="listData.length ? $t('vanPull[0]') : $t('memberRrading.default[3]')"
            :class="{ Empty: !listData.length }"
        >
          <div v-for="(item,index) in listData" :key="index">
            <div class="order-item border-grey" style="height: 102px;">
              <div style="height: auto;font-size: 13px; margin-top: 12px; width: 100%;">
                <div style="line-height: 27px; display: flex; ">
                  <div style="width: 50%;padding-left: 20px;">{{$t('memberRrading.default[1]')}} <!--交易时间-->:<!-- Quantity--></div>
                  <div style="width: 50%; text-align: end; padding-right: 20px;">
                    {{ item.add_time }}
                  </div>
                </div>
                <div style="line-height: 27px; display: flex; ">
                  <div style="width: 50%;padding-left: 20px;">{{$t('memberRrading.default[2]')}} <!--交易类型-->:<!--Not Transacted--></div>
                  <div style="width: 50%; text-align: end; padding-right: 20px;">
                    <div v-if="item.ordertype == 0">{{ $t('buy3[3]') }}<!--福利订单--></div>
                    <div v-if="item.ordertype == 1">{{ $t('buy3[4]') }}<!--合卖订单--></div>
                    <div v-if="item.ordertype == 2">{{ $t('buy3[9]') }}<!--平台订单--></div>
                  </div>
                </div>
                <div style="line-height: 27px; display: flex; ">
                  <div style="width: 50%;padding-left: 20px;">{{$t('memberRrading.default[4]')}} <!--交易金额-->:<!-- Quantity--></div>
                  <div style="width: 50%; text-align: end; padding-right: 20px;">
                    {{ item.amount }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <div style="height: 40px;"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberTrading',
  components: {},
  props: [],
  data() {
    return {
      listData: [],
      isLoad: false,
      userInfo: {},
      isFinished: false,
      pageNo: 0,
      uid :0,
    }
  },
  computed: {},
  watch: {},
  created() {
    this.usdtinfo = this.InitData.usdtinfo;
    this.uid = this.$route.query.uid;
    if(typeof this.uid == 'undefined') {
      this.uid = 0;
    }
    this.getListData("init");
  },

  mounted() {
  },
  activated() {

  },
  destroyed() {

  },
  beforeDestroy() {
  },
  methods: {
    getListData(type) {
      let that = this;
      this.isLoad = true;
      this.isRefresh = false;
      if (type == "load") {
        this.pageNo += 1;
      } else {
        this.pageNo = 1;
        this.isFinished = false;
      }

      let param = {"ordertype": this.active, status: 1, page_no: this.pageNo, page_size: 200,uid: this.uid};
      this.$Model.GetUserTaskList(param, (data) => {
        that.isLoad = false;
        if (data['code'] == 1) {
          if (data.info.length > 0) {
            data.info.forEach(item => {
              var add_time = item.add_time;
              add_time = this.$Util.timestampToDate(add_time);
              item.add_time = add_time
            });
          }

          if (type == "load") {
            if (that.pageNo == 1) {
              that.listData = data.info;
            } else {
              that.listData = that.listData.concat(data.info);
            }
          } else {
            that.listData = data.info;
          }
          if (that.pageNo == data.data_total_page) {
            that.isFinished = true;
          } else {
            that.isFinished = false;
            if (that.pageNo > data.data_total_page) {
              that.isFinished = true;
            }
          }
        } else {
          that.isFinished = true;
        }
        if(typeof data['userinfo'] != 'undefined') {
          that.userInfo = data['userinfo'];
        }

      });
    },
    startSwipe(event) {
      this.startY = event.touches[0].clientY;
    },
    endSwipe(event) {
      this.endY = event.changedTouches[0].clientY;
      if (this.endY < this.startY) {
        this.getListData('load');
        console.log('向上滑动');
      }
    }
  }
}
</script>
<style scoped lang="scss">
.PageBox {

}

.van-nav-bar {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background: var(--main-bg-color);
}

.van-nav-bar::v-deep .van-nav-bar__title {
}

.robotheader {
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 32px;
  height: 32px;
}

.href {
  color: #AD890C;
}

.records {
  width: 92%;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.Content::v-deep img {
  max-width: 100%;
}

.van-cell__title {
  font-size: 14px;
}

.ScrollBox {
  margin-top: 50px;
}

.itemDesc {
  border: 2px solid #5652FD;
  width: 36px;
  height: 36px;
  font-size: 12px;
  margin-left: 120px;
  text-align: center;
  background-color: #24303C;
  color: #fff;
  border-radius: 50%;
}

.PageBox ::v-deep .van-grid-item__content {
  padding: 10px !important;
  display: flex;
  background-color: #0000;
}

.PageBox ::v-deep .van-grid-item__text {
  color: #cccccc;
}


.title00 {
  background: #FDEAD9;
  width: 95%;
  color: #000;
  margin: auto;
  font-weight: bold;
  line-height: 40px;
  text-align: center
}

.MyHeader {
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 82px;
  height: 82px;
}

.MyHeader img {
  width: 100%;
}

.title01 {
  // background: #191C23;
  width: 95%;
  margin: auto;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  margin-bottom: 10px;

  .btn_active {
    font-size: 16px;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 60px;
      left: calc(50% - 30px);
      bottom: 5px;
      height: 5px;
      border-radius: 2.5px;
      background: #3FC3D6;
    }
  }

  .btn0 {
    font-size: 16px;
    color: #666;
  }
}

.sellitem {
  color: var(--cell_title);
  text-align: left;
  font-size: 15px;
  padding: 10px 10px;
  background-color: var(--panel_color);
  border-radius: 10px;
  margin-bottom: 10px;

  .text_name {
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #f1f1f1;
  }

  .text_name1 {
    height: 26px;
    line-height: 26px;
    font-weight: 600;

    span {
      font-weight: 400;
      width: 100px;
      display: inline-block;
    }
  }
}

.PageBox::v-deep .tool::before {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -80%;
}

.tool {
  border-radius: 16px;
  background-color: var(--panel_color);
  overflow: hidden;
  z-index: 99;
  margin: 5px 3%;
  padding: 15px 20px;
}


.ScrollBox::v-deep .van-cell {
  padding: 20px 5px;
  border-bottom: 1px solid #D9D9D9;
}

.ScrollBox::v-deep .van-cell:last-child {
  border-bottom: 0px solid #D9D9D9;
}

.ScrollBox::v-deep .van-cell::after {
  display: none;
}

.ScrollBox::v-deep .van-icon-chat-o::before {
  content: '';
  border-radius: 50%;
  background: #191C23;
  width: 8px;
  height: 8px;
}

.ScrollBox::v-deep .van-icon-chat-o {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScrollBox::v-deep .van-cell__title {
  font-size: 16px;
  font-family: ui-serif;
}

.ScrollBox::v-deep h3 {
  font-size: 1.05rem;
  font-family: emoji;
}

.van-cell::v-deep .van-icon {
  font-size: 18px;
  color: #333;
}

.hallswipe {
  width: 96%;
  //margin-left: 6px;
  padding: 15px;
  height: 26px;
  z-index: 50;
  margin-top: -9px;
  //margin-bottom: 5px;
  color: var(--font_color);
  background: transparent;
}


.ScrollBox ::v-deep .van-tab :nth-of-type(1) div {
  background-color: #000;
  padding: 5px;
  border: 1px solid;
  border-radius: 20px;
  color: #fff;
}

.ScrollBox ::v-deep .van-tab--active :nth-of-type(1) div {
  background-color: #F5C73A;
  padding: 5px;
  border-radius: 20px;
  color: #000;
}

.ScrollBox ::v-deep .van-tab--active :nth-of-type(1) div div {
  background-color: #F5C73A;
  padding: 5px;
  border-radius: 20px;
  color: #000;
}

.PageBox::v-deep .van-popup__close-icon--top-right {
  color: #333;
  top: 20px;
  right: 20px;
}

.PageBox::v-deep .Content {
  margin-top: 40px;
  padding: 0 20px;
}

.PageBox::v-deep .Content p {
  font-size: 13px;
  line-height: 1.7;
  font-family: 'vant-icon';
  font-weight: 400;
  color: #666666;
}

.info {
  padding: 0px 30px 20px 30px;

  .head {
    height: 68px;
    width: 68px;
    border-radius: 34px;
    border: 2px solid #fff;
    margin-right: 10px;
  }

  .userName {
    font-size: 20px;
    color: #fff;
    margin-bottom: 6px;
  }

  .vip {
    background: url(~@/assets/img/new/vip.png) no-repeat;
    background-size: auto 100%;
    height: 20px;
    line-height: 20px;
    padding-left: 26px;
    color: #AB783F;
    font-size: 12px;
    font-weight: 600;
    width: 80px;
  }

  .xx {
    color: #f1f1f1;
  }
}

.btn_sss {
  margin-top: 10px;

  .btn_sss1 {
    height: 48px;
    line-height: 48px;
    border-radius: 24px;
    width: calc(50% - 10px);
    background: #43407C;
    border: 2px solid #9183F1;
    text-align: center;
    font-size: 16px;
    color: #C0B7FF;
    font-weight: 600;
  }

  .btn_sss2 {
    height: 52px;
    line-height: 52px;
    border-radius: 26px;
    width: calc(50% - 10px);
    background: linear-gradient(to bottom, #A496FC, #7B6CE3, #6858D7);
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
  }
}

.box-card {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 15px 20px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 113px;
    height: 77px;
    text-align: center;
  }
}

.message {
  height: 42px;
  margin: 5px 3%;
  padding: 8px 10px;
  display: flex;
}

.welfares-order-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  margin: 5px 3%;
  margin-top: 18px;
  padding: 15px 20px;

}

.order-item {
  height: 330px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ScrollBox ::v-deep .van-tab__text {
  line-height: 20px !important;
}

</style>
